
















































































































import { DetalhesDoPagamento, Pagamento } from '@/models/venda/Pagamento';
import { formatarMoeda } from '@/shareds/formatadores';
import { obterItensComTaxaAplicada } from '@/shareds/venda-shareds';
import { PagamentoServiceAdapter } from '@/usecases';
import { Vue, Component, Ref, Watch, Prop } from 'vue-property-decorator'
import { Inject } from 'inversify-props'
import Confirmacao from '../ui/Confirmacao.vue';
import AlertModule from '@/store/vuex/aplicacao/AlertModule';
import { ResumoDaVenda } from '@/models'
import { imprimirComprovanteDeCancelamentoDoPagamento } from '@/shareds/impressao-comprovente-de-cancelamento-do-pagamento';

@Component({
	components: {
		Confirmacao,
	},
})
export default class DialogoDeCancelarPagamentos extends Vue {
	@Ref() confirmacaoDeCancelarPagamento!: Confirmacao
	@Ref() botaoDeFechar?: { $el: HTMLButtonElement }
	@Prop() venda!: ResumoDaVenda

    @Inject('PagamentoServiceAdapter')
	private pagamentoService!: PagamentoServiceAdapter

	formatarMoeda = formatarMoeda
	obterItensComTaxaAplicada = obterItensComTaxaAplicada

	mostra: boolean | null = null
	pagamentos: Pagamento[] = []
	contadorTef = 0
	aguardandoPagamento = false
	mostraAguardandoPagamento = false
	situacaoPagamento = ''
	formaDePagamentoSelecionado = ''
	tentativasEfetuarTef = 0
	retornoAdquirente: string | null = null
	comprovante: string | null = null

	@Watch('mostra')
	async findPagamentos() {
		if(!this.venda.id) return
		try {
			const pagamentos = await this.pagamentoService.findPagamentosDetalhadosByVendaId(this.venda.id)
			this.pagamentos = pagamentos
		} catch(e) {
			AlertModule.setError(e)
		}
	}

	@Watch('pagamentos')
	onChangePagamentos(pagamentos: DialogoDeCancelarPagamentos['pagamentos']) {
		if (!pagamentos.length) this.mostra = false
	}

	obterSubtituloDeExclusao(pagamento: Omit<Pagamento, 'id'>) {
		const textoDeParcelas = pagamento.qtdeDeParcelas
			? ` em ${pagamento.qtdeDeParcelas}x`
			: ''
		return `R$${formatarMoeda(pagamento.valor)} ${
			pagamento.tipoDePagamento.nome
		}${textoDeParcelas}`
	}

	@Watch('mostraAguardandoPagamento', { immediate: true })
	imprimirComprovanteDoCancelamento() {
		if (this.comprovante) {
			imprimirComprovanteDeCancelamentoDoPagamento(this.comprovante)
		}
		this.comprovante = null;
	}

	cancelarPagamentoPorIndice(indice: number) {
		const pagamento = this.pagamentos[indice]
		this.formaDePagamentoSelecionado = pagamento.tipoDePagamento.nome
		this.situacaoPagamento = 'Cancelamento Iniciado'
		this.mostraAguardandoPagamento = true
		this.cancelarPagamentoTef(pagamento, indice)
	}

	cancelarPagamentoTef(pagamento: Pagamento, indice) {
		setTimeout(async () => {
			this.contadorTef++

			if (pagamento.detalhesDoPagamentoId !== null) {
				if (this.contadorTef === 1) {
					if (!this.venda) return

					try {
						const detalhes = await this.pagamentoService.cancelarPagamentoTef(
							this.venda.pontoDeVenda.id,
							pagamento.detalhesDoPagamentoId,
						)

						this.situacaoPagamento = this.validarStatusPagamentoTefDescricao(
							detalhes,
						)

						this.cancelarPagamentoTef(pagamento, indice)
						this.comprovante = detalhes.comprovanteAdquirente
					} catch (error) {
						this.zerarPagamentoTef()
						AlertModule.setError(error)
					} 
				} else {
					try {
						if (!this.venda)
							throw new Error('Turno de venda não encontrado')

						const detalhesPagamento = await this.pagamentoService.consultarPagamentoTef(
							this.venda.pontoDeVenda.id,
							pagamento.detalhesDoPagamentoId,
						)

						this.retornoAdquirente = detalhesPagamento.msgAdquirente

						this.situacaoPagamento = this.validarStatusPagamentoTefDescricao(
							detalhesPagamento,
						)

						if (this.validarStatusPagamentoTefFinalizado(detalhesPagamento)) {
							if (
								this.validarStatusCancelamentoTefAprovado(detalhesPagamento)
							) {
								this.pagamentos.splice(indice, 1)
							}

							setTimeout(async () => {
								this.zerarPagamentoTef()
							}, 5000)
						} else {
							this.cancelarPagamentoTef(pagamento, indice)
						}
						this.comprovante = detalhesPagamento.comprovanteAdquirente
						
					} catch (error) {
						AlertModule.setError(error)
						this.zerarPagamentoTef()
					}
				}
			} else {
				this.zerarPagamentoTef()
				AlertModule.setError(
					'Pagamento não pode ser cancelado, pois não possúi pagamento com TEF relacionado',
				)
			}
		}, 5000)
	
	}

	zerarPagamentoTef() {
		this.aguardandoPagamento = false
		this.mostraAguardandoPagamento = false
		this.contadorTef = 0
		this.situacaoPagamento = ''
		this.retornoAdquirente = null
		this.tentativasEfetuarTef = 0
	}

	validarStatusCancelamentoTefAprovado(
		detalhesDoPagamento: DetalhesDoPagamento,
	) {
		switch (detalhesDoPagamento.status) {
			case '20':
				return true
			default:
				return false
		}
	}

	validarStatusPagamentoTefDescricao(detalhesDoPagamento: DetalhesDoPagamento) {
		switch (detalhesDoPagamento.status) {
			case '5':
			case '6':
				this.aguardandoPagamento = true
				return 'Aguardando pagamento, insira ou aproxime o cartão'
			case '10':
				return 'Pagamento finalizado'
			case '15':
			case '25':
				return 'Pagamento recusado'
			case '18':
			case '19':
				this.aguardandoPagamento = true
				return 'Aguardando cancelamento'
			case '20':
				return 'Pagamento cancelado'
			default:
				return 'Situação do pagamento indefinida'
		}
	}

	validarStatusPagamentoTefFinalizado(
		detalhesDoPagamento: DetalhesDoPagamento,
	) {
		switch (detalhesDoPagamento.status) {
			case '5':
			case '6':
			case '18':
			case '19':
				return false
			default:
				return true
		}
	}
}
